/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef, useContext } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
// Images
import brandSendPad from "assets/images/sendpad/sendpad-login-logo.32c527ca.svg";
import brandMailsense from "assets/images/mailsenselogo.png";
import { FeatureFlags } from "context/FeatureFlags";
import client from "ApiClient";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Grid } from "@mui/material";

function Basic() {
  const [errorSB, setErrorSB] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const closeErrorSB = () => setErrorSB(false);
  const { features } = useContext(FeatureFlags);
  const handleLogin = () => {
    const options = {
      method: "GET",
      url: `users/adminlogin`,
      params: {
        email: emailRef.current.lastChild.firstChild.value,
        password: passwordRef.current.lastChild.firstChild.value,
      },
    };
    client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) === "[{}]") setErrorSB(true);
        else {
          localStorage.setItem("AuthorizationToken", JSON.stringify(response.user_token));
          localStorage.setItem("userName", response.user_name);

          navigate("dashboard"); // window.location.href = "/"; // navigate("/blogArticles");
          window.location.reload();
        }
      })
      .catch(() => {
        setErrorSB(true);
      });
  };

  const getBrand = () => {
    switch (features.plName) {
      case "sendpad":
        return brandSendPad;
      case "mailsense":
        return brandMailsense;
      default:
        return brandSendPad;
    }
  };

  const renderErrorSB = (
    <MDSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      color="error"
      icon="warning"
      title="Login failed"
      content="Your credentails are wrong"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <PageLayout background="white">
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          size={{ xs: 12 }}
          sx={{
            backgroundImage: `url(${getBrand()})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: { xs: "90%", sm: "80%", md: "50%", lg: "30%" },
            backgroundPosition: "center",
            width: "100%",
          }}
        />
        <Grid item size={{ xs: 12 }} sx={{ width: "100%" }} display="flex">
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="top"
            width="100%"
          >
            <MDBox
              component="form"
              role="form"
              sx={{ width: { xs: "90%", sm: "80%", md: "50%", lg: "30%" } }}
            >
              <MDBox mb={2}>
                <MDInput type="email" label="Email" fullWidth ref={emailRef} />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password" fullWidth ref={passwordRef} />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" onClick={handleLogin} color="primary" fullWidth>
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {renderErrorSB}
    </PageLayout>
  );
}

export default Basic;
