/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { IconButton, TableCell } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import client from "ApiClient";
import moment from "moment";
import { Chip, CircularProgress, Grid, TextField, Tooltip } from "@mui/material";
import Subscription from "./subscription";

/* eslint-disable */
export default function Table(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const [openSubscription, setOpenSubscription] = useState(false);
  const [dateFrom, setDateFrom] = useState(null); //moment().subtract(2, "year")
  const [dateTo, setDateTo] = useState(null); //moment().add(2, "year")

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "stripe",
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `stripe/getSubscriptions`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  const getDateValue = (date) => {
    return moment(date).format("L");
  };

  const columns = [
    {
      label: "Status",
      name: "status",
      options: {
        filter: true,
        customBodyRender: (value) => {
          let color = "info";
          switch (value) {
            case "active":
              color = "success";
              break;
            case "Paused":
              color = "warning";
              break;
            case "canceled":
              color = "error";
              break;
          }
          return <Chip color={color} label={value} style={{ color: "#fff", width: "150px" }} />;
        },
        filterType: "checkbox",
      },
    },
    {
      label: "Email",
      name: "user_email",
      options: {
        filter: false,
      },
    },
    {
      label: "Plan",
      name: "price",
      options: {
        customBodyRender: (value) => "$" + value / 100,
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Frecuency",
      name: "interval",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Next billing date",
      name: "next_billing_date",
      options: {
        filter: true,
        customBodyRender: (value) =>
          value == null ? "N/A" : moment(value).format("MM/DD/yyyy HH:mm:ss"),
        sort: true,
        display: "true",
        filterType: "custom",
        filterList: [dateFrom, dateTo],
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, null);
              setDateFrom(null);
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1, null);
              setDateTo(null);
            } else if (filterPos === -1) {
              filterList[index] = [null, null];
              setDateFrom(null);
              setDateTo(null);
            }

            return filterList;
          },
          render: (v) => {
            if (v[0] != null && v[1] != null) {
              return [
                `Start Date: ${v[0].format("MM/DD/yyyy ")}`,
                `End Date: ${v[1].format("MM/DD/yyyy")}`,
              ];
            } else if (v[0] != null) {
              return `Start Date: ${v[0].format("MM/DD/yyyy")}`;
            } else if (v[1] != null) {
              return `End Date: ${v[1].format("MM/DD/yyyyy")}`;
            }
            return [];
          },
        },
        filterOptions: {
          logic: (dateTime, filters) => {
            if (moment(dateTime).isValid()) {
              //  console.log(filters);
              if (filters[0] && filters[1]) {
                return !(
                  moment(dateTime).diff(filters[0], "seconds") >= 0 &&
                  moment(dateTime).diff(filters[1], "seconds") <= 0
                );
              } else if (filters[0]) {
                return !(moment(dateTime).diff(filters[0], "seconds") >= 0);
              } else if (filters[1]) {
                return !(moment(dateTime).diff(filters[1], "seconds") <= 0);
              }
            }
            return false;
          },
          display: (filterList, onChanges, index, column) => {
            return (
              <>
                <MDBox style={{ width: "50vh" }}>Next billing date</MDBox>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    labelPlacement="top"
                    label="Select start date"
                    inputFormat="MM/DD/yyyy"
                    value={dateFrom}
                    onChange={(newValue) => {
                      filterList[3][0] = newValue;
                      setDateFrom(newValue);
                      onChanges(filterList[3], index, column);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <br />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    labelPlacement="top"
                    label="Select end date"
                    inputFormat="MM/DD/yyyy"
                    value={dateTo}
                    onChange={(newValue) => {
                      filterList[3][1] = newValue;
                      setDateTo(newValue);
                      onChanges(filterList[3], index, column);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </>
            );
          },
        },
      },
    },
    {
      label: " ",
      name: "form_id",
      options: {
        filter: false,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedData(acconuntsData[dataIndex]);
                  setOpenSubscription(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="View details">
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any subscriptions",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "subscription/s selected",
      },
    },
    print: false,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "senders.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    searchPlaceholder: "Search",
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <div>
      <Subscription
        openModal={openSubscription}
        closeModal={() => setOpenSubscription(false)}
        plan={selectedData}
        onDone={getData}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <MDTypography variant="h2">
          Subscriptions
          {isLoading && (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDTypography>
      </MDBox>

      <Grid container p={2}>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
